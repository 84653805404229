import { useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { BookCard } from "../Dashboard/helpResponsive";
import ReactHtmlParser from "react-html-parser";
import Moment from "moment";
import { useState, useEffect } from "react";
import { ResponsiveLine } from "@nivo/line";
import IMCfoto from "../../assets/newIcons/ICO-IMC.svg";
import peso1 from "../../assets/newIcons/ICO-PESO.svg";
import grasafoto1 from "../../assets/newIcons/ICO-GRASA-CORPORAL.svg";
import subcutaneafoto from "../../assets/newIcons/ICO-GRASA-SUBCUTANEA.svg";
import visceral_img from "../../assets/newIcons/ICO-GRASA-VISCERAL.svg";
import aguafoto from "../../assets/newIcons/ICO-BMR.svg";
import hueso_img from "../../assets/newIcons/ICO-MUSCULOESQUELETICO.svg";
import musculo_img from "../../assets/newIcons/ICO-MASA-MUSCULAR.svg";
import canino from "../../assets/newIcons/ICO-MASA-ESQUELETICA.svg";
import proteina_img from "../../assets/newIcons/ICO-PROTEINA.svg";
import BMRfoto from "../../assets/newIcons/ICO-AGUA-CORPORAL.svg";
import nene from "../../assets/newIcons/ICO-EDAD-CORPORAL.svg";
import "./body.css";

import { laptop575 } from "../../utils/utils";
import Estandarimagenes from "./Estandarimagenes";
import { AccordionItem } from "../FAQ";
import { Loading } from "../TutorialReserva/stepsHelp";
export const Body = (props) => {
  const navigate = useNavigate();
  const brazodegrasa = props.brazodegrasa;
  const brazode = props.brazode;
  const borderBody = props.borderBody;
  const abdomenpgrasa = props.abdomenpgrasa;
  const abdomenp = props.abdomenp;
  const piernadegrasa = props.piernadegrasa;
  const piernade = props.piernade;
  const piernaizgrasa = props.piernaizgrasa;
  const piernaiz = props.piernaiz;
  const brazoizgrasa = props.brazoizgrasa;
  const brazoiz = props.brazoiz;
  const mobileOn = props.mobileOn || false;
  const dejarsolonumber = props.dejarsolonumber;
  const data_user = props.data_user;

  return (
    <div
      id={"categoryReservaTutorial"}
      className={
        mobileOn
          ? "col-12 col-md-5 d-flex  flex-column h-100"
          : "col-12 col-md-5 d-flex justify-content-center flex-column h-100"
      }
      style={{ background: "white", padding: "2%", borderRadius: borderBody }}
    >
      <div>
        <div className="d-flex my-auto px-2" style={{ height: "auto" }}>
          <div
            onClick={() => navigate(`/Dashboard`)}
            className=" fw-bold booking-all"
            style={{
              background: "transparent",
              border: "1px solid #A8C2D7",
              padding: "1%",
              fontSize: "1vmin",
              height: "fit-content",
              cursor: "pointer",
              color: "#A8C2D7",
              borderRadius: "10px",
            }}
          >
            <span style={{ fontSize: "0.5vmin" }}>
              <i className="bi bi-chevron-left"></i>
            </span>
          </div>
          <span className="fw-bold my-auto ms-2 text_arrow_top">
            {" "}
            Conoce tu cuerpo
          </span>
        </div>
        <hr />
      </div>

      <div
        className="position-relative"
        style={{
          background: "#F9F9F9",
          width: "calc(100%)",
          borderRadius: borderBody,
          flexDirection: "column",
          display: "flex",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className=" letraPequeña d-flex w-100 "
          style={{
            padding: "0vmin 10vmin",
            justifyContent: "space-evenly",
            color: "var(--elevvaTextTitle)",
          }}
        >
          <span className="letraBody m-auto py-2">
            <img
              alt="bascula"
              className="mx-1 image_bascula"
              src={process.env.PUBLIC_URL + "/assets/img/bascula.svg"}
            />
            {/* <strong>Actual:</strong> 78,8 Kg */}
            <strong>Actual:</strong>{" "}
            {data_user?.customer?.optimal_weight
              ? data_user.customer.weight.toFixed(2) + " Kg"
              : " ---"}
          </span>
          <span className="letraBody m-auto">
            <img
              alt="bascula1"
              className="mx-1 image_bascula"
              src={process.env.PUBLIC_URL + "/assets/img/bascula.svg"}
            />{" "}
            <strong>Objetivo: </strong>
            <span className="letraBody" style={{ color: "var(--elevva)" }}>
              {data_user?.customer?.optimal_weight
                ? data_user.customer?.optimal_weight.min?.toFixed(2) +
                  " - " +
                  data_user.customer.optimal_weight?.max.toFixed(2) +
                  " Kg"
                : " ---"}
            </span>
          </span>
        </div>
        <img
          alt="FRENTE"
          className="figure_your_body"
          src={process.env.PUBLIC_URL + "/assets/img/onboarding/FRENTE.png"}
        />
        <div
          className="position-absolute "
          style={{
            top: "12%",
            left: "10%",
            width: "30%",
            transform: "rotate(-360deg)",
          }}
        >
          <span
            className="letraBodyInside"
            style={{ color: "var(--elevvabutonLogin_User)" }}
          >
            Brazo derecho
          </span>
          <div style={{ lineHeight: "1.5vmin" }}>
            {" "}
            <span className="letraBodyInside">
              {/* <i className="bi bi-caret-down-fill"></i> Grasa corporal: 5,8% */}
              {Array.isArray(brazodegrasa) ? (
                brazodegrasa[0] >= 0 ? (
                  <i className="bi bi-caret-up-fill"></i>
                ) : (
                  <i className="bi bi-caret-down-fill"></i>
                )
              ) : null}{" "}
              Grasa corporal:{" "}
              {Array.isArray(brazodegrasa)
                ? dejarsolonumber(brazodegrasa[0].toFixed(1))
                : brazodegrasa.toFixed(1)}
              %
            </span>
            <br />
            <span className="letraBodyInside">
              {Array.isArray(brazode) ? (
                brazode[0] >= 0 ? (
                  <i
                    className="bi bi-caret-up-fill"
                    style={{ color: "var(--elevvabutonLogin_User)" }}
                  ></i>
                ) : (
                  <i
                    style={{ color: "var(--elevvabutonLogin_User)" }}
                    className="bi bi-caret-down-fill"
                  ></i>
                )
              ) : null}{" "}
              Masa muscular:{" "}
              {Array.isArray(brazode)
                ? dejarsolonumber(brazode[0].toFixed(1))
                : brazode.toFixed(1)}
              Kg
            </span>
          </div>
        </div>
        <div
          className="position-absolute"
          style={{
            top: "12%",
            right: "10%",
            width: "30%",
            textAlign: "right",
          }}
        >
          <span
            className="letraBodyInside "
            style={{ color: "var(--elevvabutonLogin_User)" }}
          >
            Brazo izquierdo
          </span>
          <div style={{ lineHeight: "1.5vmin" }}>
            {" "}
            <span className="letraBodyInside ">
              {/* Grasa corporal: 5,8% */}
              Grasa corporal:{" "}
              {Array.isArray(brazoizgrasa)
                ? dejarsolonumber(brazoizgrasa[0].toFixed(1))
                : brazoizgrasa.toFixed(1)}
              %
              {Array.isArray(brazoizgrasa) ? (
                brazoizgrasa[0] >= 0 ? (
                  <i className="bi bi-caret-up-fill"></i>
                ) : (
                  <i className="bi bi-caret-down-fill"></i>
                )
              ) : null}{" "}
            </span>
            <br />
            <span className="letraBodyInside ">
              {/* Masa muscular: 37 kg{" "} */}
              Masa muscular:{" "}
              {Array.isArray(brazoiz)
                ? dejarsolonumber(brazoiz[0].toFixed(1))
                : brazoiz.toFixed(1)}
              Kg
              {Array.isArray(brazoiz) ? (
                brazoiz[0] >= 0 ? (
                  <i
                    className="bi bi-caret-up-fill"
                    style={{ color: "var(--elevvabutonLogin_User)" }}
                  ></i>
                ) : (
                  <i
                    style={{ color: "var(--elevvabutonLogin_User)" }}
                    className="bi bi-caret-down-fill"
                  ></i>
                )
              ) : null}
            </span>
          </div>
        </div>

        <span
          className="position-absolute linebodyInicio"
          style={{ top: "22%", left: "10%", width: "30%" }}
        >
          <hr />
          <span
            className="rounded-circle position-absolute"
            style={{
              background: "var(--elevvaTexttitleLow)",
              width: "8px",
              height: "8px",
              top: "40%",
              left: "100%",
            }}
          ></span>
        </span>
        <div
          className="position-absolute "
          style={{
            top: "40%",
            left: "10%",
          }}
        >
          <span
            className="letraBodyInside"
            style={{ color: "var(--elevvabutonLogin_User)" }}
          >
            Abdomen
          </span>
          <div style={{ lineHeight: "1.5vmin" }}>
            {" "}
            <span className="letraBodyInside">
              {/* <i className="bi bi-caret-down-fill"></i> Grasa corporal: 5,8% */}
              {Array.isArray(abdomenpgrasa) ? (
                abdomenpgrasa[0] >= 0 ? (
                  <i className="bi bi-caret-up-fill"></i>
                ) : (
                  <i className="bi bi-caret-down-fill"></i>
                )
              ) : null}{" "}
              Grasa corporal:
              {Array.isArray(abdomenpgrasa)
                ? dejarsolonumber(abdomenpgrasa[0].toFixed(1))
                : abdomenpgrasa.toFixed(1)}
              %
            </span>
            <br />
            <span className="letraBodyInside">
              {Array.isArray(abdomenp) ? (
                abdomenp[0] >= 0 ? (
                  <i
                    className="bi bi-caret-up-fill"
                    style={{ color: "var(--elevvabutonLogin_User)" }}
                  ></i>
                ) : (
                  <i
                    style={{ color: "var(--elevvabutonLogin_User)" }}
                    className="bi bi-caret-down-fill"
                  ></i>
                )
              ) : null}{" "}
              {/* Masa muscular: 37 kg */}
              Masa muscular:{" "}
              {Array.isArray(abdomenp)
                ? dejarsolonumber(abdomenp[0].toFixed(1))
                : abdomenp.toFixed(1)}
              Kg
            </span>
          </div>
        </div>
        <div
          className="position-absolute "
          style={{
            top: "64%",
            left: "10%",
            width: "30%",
            transform: "rotate(-360deg)",
          }}
        >
          <span
            className="letraBodyInside"
            style={{ color: "var(--elevvabutonLogin_User)" }}
          >
            Pierna derecha
          </span>
          <div style={{ lineHeight: "1.5vmin" }}>
            {" "}
            <span className="letraBodyInside">
              {/* <i className="bi bi-caret-down-fill"></i> Grasa corporal: 5,8% */}
              {Array.isArray(piernadegrasa) ? (
                piernadegrasa[0] >= 0 ? (
                  <i className="bi bi-caret-up-fill"></i>
                ) : (
                  <i className="bi bi-caret-down-fill"></i>
                )
              ) : null}{" "}
              Grasa corporal:{" "}
              {Array.isArray(piernadegrasa)
                ? dejarsolonumber(piernadegrasa[0].toFixed(1))
                : piernadegrasa.toFixed(1)}
              %
            </span>
            <br />
            <span className="letraBodyInside">
              {Array.isArray(piernade) ? (
                piernade[0] >= 0 ? (
                  <i
                    className="bi bi-caret-up-fill"
                    style={{ color: "var(--elevvabutonLogin_User)" }}
                  ></i>
                ) : (
                  <i
                    style={{ color: "var(--elevvabutonLogin_User)" }}
                    className="bi bi-caret-down-fill"
                  ></i>
                )
              ) : null}{" "}
              {/* Masa muscular: 37 kg */}
              Masa muscular:{" "}
              {Array.isArray(piernade)
                ? dejarsolonumber(piernade[0].toFixed(1))
                : piernade.toFixed(1)}
              Kg
            </span>
          </div>
        </div>
        <div
          className="position-absolute"
          style={{
            top: "64%",
            right: "10%",
            width: "30%",
            textAlign: "right",
          }}
        >
          <span
            className="letraBodyInside "
            style={{ color: "var(--elevvabutonLogin_User)" }}
          >
            Pierna izquierda
          </span>
          <div style={{ lineHeight: "1.5vmin" }}>
            {" "}
            <span className="letraBodyInside ">
              {/* Grasa corporal: 5,8% */}
              Grasa corporal:{" "}
              {Array.isArray(piernaizgrasa)
                ? dejarsolonumber(piernaizgrasa[0].toFixed(1))
                : piernaizgrasa.toFixed(1)}
              %
              {Array.isArray(piernaizgrasa) ? (
                piernaizgrasa[0] >= 0 ? (
                  <i
                    className="bi bi-caret-up-fill"
                    style={{ color: "var(--elevvabutonLogin_User)" }}
                  ></i>
                ) : (
                  <i
                    style={{ color: "var(--elevvabutonLogin_User)" }}
                    className="bi bi-caret-down-fill"
                  ></i>
                )
              ) : null}
            </span>
            <br />
            <span className="letraBodyInside ">
              {/* Masa muscular: 37 kg{" "} */}
              Masa muscular:{" "}
              {Array.isArray(piernaiz)
                ? dejarsolonumber(piernaiz[0].toFixed(1))
                : piernaiz.toFixed(1)}
              Kg
              {Array.isArray(piernaiz) ? (
                piernaiz[0] >= 0 ? (
                  <i className="bi bi-caret-up-fill"></i>
                ) : (
                  <i className="bi bi-caret-down-fill"></i>
                )
              ) : null}{" "}
            </span>
          </div>
        </div>
        <span
          className="position-absolute linebodyInicio"
          style={{ top: "37%", left: "10%", width: "38%" }}
        >
          <hr />
          <span
            className="rounded-circle position-absolute "
            style={{
              background: "var(--elevvaTexttitleLow)",
              width: "8px",
              height: "8px",
              top: "40%",
              left: "100%",
            }}
          ></span>
        </span>
        <span
          className="position-absolute linebodyInicio"
          style={{
            top: "22%",
            right: "10%",
            width: "30%",
            transform: "rotate(-180deg)",
          }}
        >
          <hr />
          <span
            className="rounded-circle position-absolute "
            style={{
              background: "var(--elevvaTexttitleLow)",
              width: "8px",
              height: "8px",
              top: "40%",
              left: "100%",
            }}
          ></span>
        </span>
        <span
          className="position-absolute linebodyInicio"
          style={{ top: "60%", left: "10%", width: "35%" }}
        >
          <hr />
          <span
            className="rounded-circle position-absolute"
            style={{
              background: "var(--elevvaTexttitleLow)",
              width: "8px",
              height: "8px",
              top: "40%",
              left: "100%",
            }}
          ></span>
        </span>
        <span
          className="position-absolute linebodyInicio"
          style={{
            top: "60%",
            right: "10%",
            width: "35%",
            transform: "rotate(-180deg)",
          }}
        >
          <hr />
          <span
            className="rounded-circle position-absolute"
            style={{
              background: "var(--elevvaTexttitleLow)",
              width: "8px",
              height: "8px",
              top: "40%",
              left: "100%",
            }}
          ></span>
        </span>
      </div>
    </div>
  );
};

export const InfoBody = (props) => {
  const [estrella1, setEstrella1] = useState([]);
  const mobileOn = props.mobileOn || false;
  const data_user = props.data_user;
  const borderBody = props.borderBody;
  const loading = props.loading;
  const Imc = props.Imc;
  const pesoActual = props.pesoActual;
  const grasa = props.grasa;
  const perdida = props.perdida;
  const subcutanea = props.subcutanea;
  const visceral = props.visceral;
  const water = props.water;
  const hueso = props.hueso;
  const esqueletica = props.esqueletica;
  const musculo = props.musculo;
  const proteina = props.proteina;
  const bmr = props.bmr;
  const edad = props.edad;
  const infoHeight  = props.infoHeight || null;
  const widthInfoBody =props.widthInfoBody || "col-md-7";
  const corporativeInfo =props.corporativeInfo || false;
  const array001 = [
    {
      data: [{ x: 0, y: 0 }],
    },
  ];

  const theme1 = {
    axis: {
      fontSize: "3px",

      domain: {
        line: {
          stroke: "#959595",
          strokeWidth: 0.5,
        },
      },
      ticks: {
        line: {
          stroke: "#959595",
          strokeWidth: 0.5,
        },
        text: {
          fill: "#555555",
        },
      },
      legend: {
        text: {
          fontSize: laptop575.matches ? "1vw" : "3vw",
          fill: "#2A9DA5",
          fontWeight: "700",
        },
      },
    },
    grid: {
      line: {
        stroke: "#ffffff",

        strokeWidth: 1,
      },
    },
  };
  const formula =
    pesoActual && pesoActual[0]
      ? pesoActual[0] / Math.pow(data_user.customer?.height / 100, 2)
      : null;
  function formula2(dato) {
    if (data_user.customer?.gender == 1) {
      if (data_user.customer?.age < 40) {
        if (dato < 8) return "BAJO";
        else if (dato < 21 && dato > 7) return "SALUDABLE";
        else if (dato < 26 && dato > 19) return "ALTO";
        else return "MUY ALTO";
      } else if (data_user.customer?.age > 39 && data_user.customer?.age < 60) {
        if (dato < 11) return "BAJO";
        else if (dato < 23 && dato > 10) return "SALUDABLE";
        else if (dato < 29 && dato > 21) return "ALTO";
        else return "MUY ALTO";
      } else if (data_user.customer?.age > 59) {
        if (dato < 13) return "BAJO";
        else if (dato < 26 && dato > 12) return "SALUDABLE";
        else if (dato < 31 && dato > 24) return "ALTO";
        else return "MUY ALTO";
      }
    } else {
      if (data_user.customer?.age < 40) {
        if (dato < 21) return "BAJO";
        else if (dato < 34 && dato > 20) return "SALUDABLE";
        else if (dato < 40 && dato > 32) return "ALTO";
        else return "MUY ALTO";
      } else if (data_user.customer?.age > 39 && data_user.customer?.age < 60) {
        if (dato < 23) return "BAJO";
        else if (dato < 36 && dato > 22) return "SALUDABLE";
        else if (dato < 41 && dato > 34) return "ALTO";
        else return "MUY ALTO";
      } else if (data_user.customer?.age > 59) {
        if (dato < 24) return "BAJO";
        else if (dato < 37 && dato > 23) return "SALUDABLE";
        else if (dato < 43 && dato > 35) return "ALTO";
        else return "MUY ALTO";
      }
    }
  }
  function formula3(dato) {
    if (data_user.customer?.gender == 1) {
      if (dato < 1) return "BAJO";
      else if (dato < 4 && dato > 0) return "ESENCIAL";
      else if (dato < 21 && dato > 3) return "NORMAL";
      else return "ALTO";
    } else {
      if (dato < 1) return "BAJO";
      else if (dato < 13 && dato > 0) return "ESENCIAL";
      else if (dato < 29 && dato > 12) return "NORMAL";
      else return "ALTO";
    }
  }
  function formula4(dato) {
    if (data_user.customer?.gender == 1) {
      if (data_user.customer?.age < 31) {
        if (dato < 43) return "BAJO";
        else if (dato < 57 && dato > 42) return "NORMAL";
        else return "ALTO";
      } else if (data_user.customer?.age > 30 && data_user.customer?.age < 61) {
        if (dato < 40) return "BAJO";
        else if (dato < 51 && dato > 39) return "NORMAL";
        else return "ALTO";
      } else if (data_user.customer?.age > 59) {
        if (dato < 38) return "BAJO";
        else if (dato < 58 && dato > 37) return "NORMAL";
        else return "ALTO";
      }
    } else {
      if (data_user.customer?.age < 31) {
        if (dato < 35) return "BAJO";
        else if (dato < 42 && dato > 34) return "NORMAL";
        else return "ALTO";
      } else if (data_user.customer?.age > 30 && data_user.customer?.age < 61) {
        if (dato < 33) return "BAJO";
        else if (dato < 39 && dato > 32) return "NORMAL";
        else return "ALTO";
      } else if (data_user.customer?.age > 59) {
        if (dato < 28) return "BAJO";
        else if (dato < 34 && dato > 27) return "NORMAL";
        else return "ALTO";
      }
    }
  }
  function formula5(dato) {
    if (data_user.customer?.gender == 1) {
      if (dato < 6 && dato > 2) return "PROMEDIO";
      else return "PARTICULAR";
    } else {
      if (dato < 4 && dato > 1) return "PROMEDIO";
      else return "PARTICULAR";
    }
  }
  function formula6(dato) {
    if (data_user.customer?.gender == 1) {
      if (dato < 51) return "BAJO";
      else if (dato < 66 && dato > 50) return "SALUDABLE";
      else return "ALTO";
    } else {
      if (dato < 46) return "BAJO";
      else if (dato < 61 && dato > 45) return "SALUDABLE";
      else return "ALTO";
    }
  }
  function formula7(dato) {
    if (data_user.customer?.gender == 1) {
      if (data_user.customer?.age < 40) {
        if (dato < 33.3) return "BAJA";
        else if (dato < 39.4 && dato > 33.2) return "NORMAL";
        else if (dato < 45 && dato > 39.3) return "ALTA";
        else return "MUY ALTA";
      } else if (data_user.customer?.age > 39 && data_user.customer?.age < 60) {
        if (dato < 33.1) return "BAJA";
        else if (dato < 39.2 && dato > 33.0) return "NORMAL";
        else if (dato < 43.9 && dato > 39.1) return "ALTA";
        else return "MUY ALTA";
      } else if (data_user.customer?.age > 59) {
        if (dato < 32.9) return "BAJA";
        else if (dato < 39 && dato > 32.8) return "NORMAL";
        else if (dato < 43.7 && dato > 38.9) return "ALTA";
        else return "MUY ALTA";
      }
    } else {
      if (data_user.customer?.age < 40) {
        if (dato < 24.3) return "BAJA";
        else if (dato < 30.4 && dato > 24.2) return "NORMAL";
        else if (dato < 35.4 && dato > 30.3) return "ALTA";
        else return "MUY ALTA";
      } else if (data_user.customer?.age > 39 && data_user.customer?.age < 60) {
        if (dato < 24.1) return "BAJA";
        else if (dato < 30.2 && dato > 24) return "NORMAL";
        else if (dato < 35.2 && dato > 30.1) return "ALTA";
        else return "MUY ALTA";
      } else if (data_user.customer?.age > 59) {
        if (dato < 23.9) return "BAJA";
        else if (dato < 30 && dato > 23.8) return "NORMAL";
        else if (dato < 35 && dato > 29.9) return "ALTA";
        else return "MUY ALTA";
      }
    }
  }
  const selOptionCuerpo = [
    [
      "Peso", //0
      peso1,
      formula < 18.5
        ? "MUY BAJO"
        : formula > 18.4 && formula < 25
        ? "SALUDABLE"
        : formula > 24.9 && formula < 30
        ? "ALTO"
        : "MUY ALTO",
      pesoActual ? pesoActual[0].toFixed(1) + " Kg" : 0,
      pesoActual ? pesoActual[1] : array001,
      pesoActual ? (pesoActual[0] - pesoActual[2]).toFixed(1) + " Kg" : 0,
    ],
    [
      "IMC", //1
      IMCfoto,
      Imc && Imc[0] < 18.5
        ? "MUY BAJO"
        : Imc && Imc[0] > 18.4 && Imc[0] < 25
        ? "SALUDABLE"
        : Imc && Imc[0] > 24.9 && Imc[0] < 30
        ? "ALTO"
        : "MUY ALTO",
      Imc ? Imc[0].toFixed(1) : 0,
      Imc ? Imc[1] : array001,
      Imc ? (Imc[0] - Imc[2]).toFixed(1) : 0,
    ],
    [
      "Grasa corporal", //2
      grasafoto1,
      grasa ? formula2(grasa[0]) : "____",
      grasa ? grasa[0].toFixed(1) + " %" : 0,
      grasa ? grasa[1] : array001,

      grasa ? (grasa[0] - grasa[2]).toFixed(1) + " %" : 0,
    ],
   
    [
      "Grasa subcutaánea", //3
      subcutaneafoto,
      subcutanea ? formula3(subcutanea[0]) : "____",
      subcutanea ? subcutanea[0].toFixed(1) + " %" : 0,
      subcutanea ? subcutanea[1] : array001,
      subcutanea ? (subcutanea[0] - subcutanea[2]).toFixed(1) + " %" : 0,
    ],
    [
      "Grasa visceral", //4
      visceral_img,
      visceral && visceral[0] > 11 ? "EXCESIVO" : "SALUDABLE",
      visceral ? visceral[0].toFixed(1) : 0,
      visceral ? visceral[1] : array001,
      visceral ? (visceral[0] - visceral[2]).toFixed(1) : 0,
    ],
    [
      "Agua corporal",  //5
      BMRfoto,
      water ? formula6(water[0]) : "____",
      water ? water[0].toFixed(1) + " %" : 0,
      water ? water[1] : array001,
      water ? (water[0] - water[2]).toFixed(1) + " %" : 0,
    ],
    [
      " Músculo esquelético", //6
      hueso_img,
      hueso ? formula7(hueso[0]) : "____",
      hueso ? hueso[0].toFixed(1) + " %" : 0,
      hueso ? hueso[1] : array001,
      hueso ? (hueso[0] - hueso[2]).toFixed(1) + " %" : 0,
    ],
    [
      "Masa esquelética", //7
      canino,
      esqueletica ? formula5(esqueletica[0]) : "____",
      esqueletica ? esqueletica[0].toFixed(1) + " %" : 0,
      esqueletica ? esqueletica[1] : array001,
      esqueletica ? (esqueletica[0] - esqueletica[2]).toFixed(1) + " %" : 0,
    ],
    [
      "  Masa muscular", //8
      musculo_img,
      musculo ? formula4(musculo[0]) : "____",
      musculo ? musculo[0].toFixed(1) + " Kg" : 0,
      musculo ? musculo[1] : array001,
      musculo ? (musculo[0] - musculo[2]).toFixed(1) + " Kg" : 0,
    ],
    [
      " Proteina", //9
      proteina_img,
      proteina && proteina[0] > 20
        ? "EXCESIVO"
        : proteina && proteina[0] < 16
        ? "BAJO"
        : "NORMAL",
      proteina ? proteina[0].toFixed(1) + " %" : 0,
      proteina ? proteina[1] : array001,
      proteina ? (proteina[0] - proteina[2]).toFixed(1) + " %" : 0,
    ],
    [
      "BMR",  //10
      aguafoto,
      "____",
      bmr ? bmr[0] : 0,
      bmr ? bmr[1] : array001,
      bmr ? (bmr[0] - bmr[2]).toFixed(1) : 0,
    ],
    [
      " Edad corporal",  //11
      nene,
      "____",
      edad ? edad[0].toFixed(1) : 0,
      edad ? edad[1] : array001,
      edad ? (edad[0] - edad[2]).toFixed(1) : 0,
    ],
  ];
  
  useEffect(() => {
    const arrData = [];
    let count = 0;
    let title = selOptionCuerpo.map((e, i) => (
      <div className={"d-flex justify-content-around w-100"} key={i}>
        <div className="d-flex col-4">
          <div className="d-flex col-4 " >
            <img className="d-flex m-auto p-auto imageInfo_icon" src={e[1]} />
          </div>
          <div className=" d-flex my-auto ms-4 p-auto col-7 col-md-8 letra_body_info">
            {e[0]}
          </div>
        </div>
        {e[2] == "ALTO" ||
        e[2] == "BAJO" ||
        e[2] == "MUY BAJO" ||
        e[2] == "ALTA" ||
        e[2] == "EXCESIVO" ||
        e[2] == "MUY ALTO" ? (
          <div className="d-flex m-auto p-auto col-5 justify-content-center my-auto letra_body_info active text-center">
            {e[2] == "ALTO" ||
            e[2] == "ALTA" ||
            e[2] == "EXCESIVO" ||
            e[2] == "MUY ALTO"
              ? "Estas por encima de tu nivel adecuado"
              : "Estas por debajo de tu nivel adecuado"}
          </div>
        ) : (
          <div
            className={
              " d-flex m-auto p-auto col-5 justify-content-center my-auto letra_body_info text-center"
            }
          >
            {e[2] == "SALUDABLE" || e[2] == "NORMAL" || e[2] == "PROMEDIO"
              ? "Tu nivel es adecuado"
              : e[2]}
          </div>
        )}

        <div
          className={
            " d-flex m-auto p-auto  col-2 justify-content-center letra_body_info"
          }
        >
          {e[3] ? e[3] : 0}
        </div>
      </div>
    ));

    arrData.push(title);
    if (selOptionCuerpo.length > 0) {
      count += 1;
    }
    if (count > 0) {
      setEstrella1(arrData);
    }
  }, [loading]);

  return loading ? (
    <div
      className={
        mobileOn
          ? "col-12 col-md-7 d-flex  flex-column h-100"
          : "col-12 col-md-7 d-flex justify-content-center flex-column h-100"
      }
      style={{
        background: "white",
        padding: "2%",
        borderRadius: borderBody,
      }}
    >
      {" "}
      <Loading />{" "}
    </div>
  ) : (
    <div
      className={
        mobileOn
          ? "col-12 col-md-7 d-flex  flex-column h-100"
          : `col-12 ${widthInfoBody} d-flex justify-content-center flex-column h-100`
      }
      style={{
        background: "white",
        padding: "2%",
        borderRadius: borderBody,
      }}
    >
      {mobileOn ? <></> : (
     corporativeInfo?null:   <div>
          <div
            className="d-flex my-auto justify-content-center"
            style={{ height: "auto" }}
          >
            <span className="fw-bold my-auto ms-2 text_arrow_top">
              {" "}
              Datos e información de tu cuerpo
            </span>
          </div>
          <hr />
        </div>
      )}
      <PerfectScrollbar 
        className="acordeonbum  "
        style={mobileOn ? { padding: "0 3%" } :infoHeight?{ padding: "0 10% 0 5%",height:infoHeight}: { padding: "0 10% 0 5%"}}
      >
        {!estrella1[0]
          ? <></>
          : estrella1[0].map((acordeon, indice) => (<div key={"body-acordeon"+ indice}>
              <AccordionItem
                title={acordeon}
                identify={indice}
                item={indice}
                clase="quitarcuadro"
              >
                <div className="lomores">
                  <div className=" d-flex  jc-center kilogramos ">
                    <div className=" d-flex m-auto p-auto jc-center  letrasviscerasadentro">
                      {selOptionCuerpo[indice][3]}
                    </div>

                    <div
                      className={`d-flex m-auto p-auto kilogramos_text`}
                      style={
                        selOptionCuerpo[indice][5] >= 0
                          ? { color: "var(--elevvaArrowColor)" }
                          : { color: "var(--elevvabutonLogin_User)" }
                      }
                    >
                      {selOptionCuerpo[indice][5]}
                    </div>
                    <div style={{ margin: "1vh 3vw" }}>
                      {selOptionCuerpo[indice][5] >= 0 ? (
                        <i className="bi bi-caret-up-fill"></i>
                      ) : (
                        <i
                          style={{ color: "var(--elevvabutonLogin_User)" }}
                          className="bi bi-caret-down-fill"
                        ></i>
                      )}
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        height: 250,
                        marginBottom: "0vh",
                        width: "100%",
                        marginLeft: "0%",
                      }}
                    >
                      <ResponsiveLine
                        data={selOptionCuerpo[indice][4]}
                        margin={{
                          top: 50,
                          right: 35,
                          bottom: 50,
                          left: 35,
                        }}
                        xScale={{ type: "point" }}
                        yScale={{
                          type: "linear",
                          min: "auto",
                          max: "auto",

                          reverse: false,
                        }}
                        axisTop={null}
                        axisRight={null}
                        enableGridX={false}
                        lineWidth={2}
                        axisBottom={{
                          orient: "bottom",
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: "",
                          legendOffset: 36,
                          legendPosition: "middle",
                        }}
                        axisLeft={{
                          orient: "left",
                          tickSize: 4,
                          tickPadding: 5,
                          tickRotation: 0,
                          legendOffset: -40,
                          legendPosition: "middle",
                        }}
                        colors={["var(--pointsGrafica)"]}
                        theme={theme1}
                        pointSize={9}
                        pointColor={"#ffffff"}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: "serieColor" }}
                        pointLabel="y"
                        enableArea={true}
                        areaBaselineValue={
                          selOptionCuerpo[indice][4][0].data
                            .map((e, i) => e.y)
                            .sort()[0]
                        }
                        areaOpacity={0.3}
                        defs={[
                          {
                            id: "gradientC",

                            type: "linearGradient",

                            colors: [
                              { offset: 15, color: "var(--pointsGrafica)" },

                              { offset: 100, color: "#ffff" },
                            ],
                          },
                        ]}
                        fill={[{ match: "*", id: "gradientC" }]}
                        useMesh={true}
                        tooltip={(data) => {
              // Aquí creamos nuestro tooltip personalizado
              // Extrayendo el nombre de la serie y el valor del punto
              const { point } = data;
              return (
                <div>
                  <div
                    className="text-center p-2 elevation-18"
                    style={{
                      color: "#A8C2D7",
                      background: "#fff",
                      fontSize: "calc(0.6rem + 0.2vmin)",
                      borderRadius: "5px",
                    }}
                  >
                    {point.serieId} <br /> {Math.round(point.data.yFormatted)}
                  </div>
                </div>
              );
            }}
                      />
                    </div>
                    { indice == 10 || indice == 11 ? null : (
                      <Estandarimagenes
                        nameObjetivo={indice}
                        dateObjetivo={selOptionCuerpo[indice][2]}
                      />
                    )}
                  </div>
                </div>
              </AccordionItem></div>
            ))}
      </PerfectScrollbar>
    </div>
  );
};
