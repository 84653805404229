import React, { useRef, useState } from "react";
import "../../utils/utils.css";

import { useNavigate, useLocation } from "react-router-dom";
import { ButtonMob } from "../../utils/utils";

export const ModalProfe = React.forwardRef((props, ref) => {

  const navigate = useNavigate();
  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "absolute";
  const handleCancel = props.handleCancel;
  const handleCancellcargue = props.handleCancellcargue;
  // const elevvaT = props.elevvaT;
  const [inputs, setInputs] = useState({
    answer_1: false,
    answer_3: false,
  });

  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
  };
  const filterEntrenador = [
    [
      "Hombre",
      process.env.PUBLIC_URL + "/assets/img/onboarding/otrosA.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/otrosB.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Mujer",
      process.env.PUBLIC_URL + "/assets/img/onboarding/yogaA.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/yogaB.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
  ];
  const [filter_entrenador, setfilter_entrenador] = useState(
    new Array(filterEntrenador.length)
  );

  const acompañamiento = [
    [
      "Presión constante",
      process.env.PUBLIC_URL + "/assets/img/onboarding/presion_constanteB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/presion_constanteA.svg",
      useRef(null),
      useRef(null),
      useRef(null),

    ],
    [
      "Paciente y calmado",
      process.env.PUBLIC_URL + "/assets/img/onboarding/calmadoB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/calmadoA.svg",
      useRef(null),
      useRef(null),
      useRef(null),


    ],
  ]
  const [acompañamientoP, setacompañamientoP] = useState(
    new Array(acompañamiento.length)
  );
  var handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setInputs((val) => ({ ...val, [name]: value }));
  };
  const rangoEdad =
    [
      ["De 20 a 30 años", useRef(null)],
      ["30 a 40 años", useRef(null)],
      ["Más de 40 años", useRef(null)],
    ]
  const selectCircleText = (refContainer, index, classess, arr, onlyone) => {
    if (onlyone === true) {
      for (const iterator of arr) {
        iterator[1].current.classList = classess;
        iterator[1].current.active = false;
        iterator[2] = false;
      }
    }
    if (refContainer.current.active == true) {
      refContainer.current.classList = classess;
      refContainer.current.active = false;
    } else {
      refContainer.current.classList = classess + " active";
      refContainer.current.active = true;
    }
    if (arr === rangoEdad) {
      rangoEdad[index][2] = refContainer.current.active;
      inputs["transportation_time"] = index;
    }
  };

  const selectCircleImg = (
    refContainer,
    refImg,
    index,
    classess,
    arr,
    onlyone
  ) => {
    if (onlyone == true) {
      for (const iterator of arr) {
        iterator[3].current.classList = classess;
        iterator[3].current.style.fontWeight = `400`;
        iterator[3].current.active = false;
        iterator[4].current.style.backgroundImage = `url(${iterator[1]})`;
        iterator[4].current.style.border = `6px solid var(--backgroundOnboardingObjectiveBorderOff)`;
        iterator[4].current.style.backgroundColor = `var(--backgroundOnboardingObjectiveBorderOff)`;
        iterator[5] = false;
      }
    }
    if (refContainer.current.active === true) {
      refContainer.current.classList = classess;
      refContainer.current.active = false;
      refImg.current.style.backgroundImage = `url(${arr[index][1]})`;
    } else {
      refContainer.current.style.fontWeight = `600`;
      refContainer.current.classList = classess + " active";
      refContainer.current.active = true;
      refImg.current.style.backgroundImage = `url(${arr[index][2]})`;
      refImg.current.style.backgroundColor = `var(--backgroundOnboardingObjective)`;
    }

    if (arr === filterEntrenador) {
      filterEntrenador[index][5] = refContainer.current.active;
      const copy = filter_entrenador;
      copy[index] = refContainer.current.active;
      setfilter_entrenador(copy);
    }
    if (arr === acompañamiento) {
      [index][5] = refContainer.current.active;
      const copy = acompañamientoP;
      copy[index] = refContainer.current.active;
      setacompañamientoP(copy);
    }
  };
  return (
    <div
      ref={ref}
      className="modalInfo justify-content-center align-items-center"
      style={styleModal}
    >
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/img/backgroundElevva-logo.jpg"
            })`,
          backgroundSize: "cover",
          backgroundPositionX: "center",
          backgroundRepeat: "no-repeat",
          borderBottom: "1px solid",
        }}
        className={"modalInfoContenido col-8"}
      >
        {error === "none" || error === "true" ? (
          <div
            className=""
            style={{
              position: "absolute",
              top: "6%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.0rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={handleCancel}
          >
            <i class="bi bi-x-circle"></i>
          </div>
        ) : (
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "2.5vmin",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={handleCancellcargue ? handleCancellcargue : handleCancel}
          >
            <i class="bi bi-x-circle"></i>
          </div>
        )}

        <div className="col-12 d-flex" style={{ borderBottom: "1px solid" }}>

          <div className="d-flex my-auto col-1">
            <div
              // onClick={() => navigate(`/Usuario/Inicio`)}
              className=" fw-bold booking-all"
              style={{
                background: "transparent",
                border: "1px solid #A8C2D7",
                padding: "2%",
                fontSize: "1vmin",
                height: "fit-content",
                cursor: "pointer",
                color: "#A8C2D7",
                borderRadius: "10px",
              }}
            >
              <span style={{ fontSize: "0.5vmin" }}>
                <i className="bi bi-chevron-left"></i>
              </span>

            </div>

          </div>

          <div
            onClick={() => navigate(`/Usuario/Inicio`)} className=" fw-bold booking-all"
            style={{
              background: "transparent",
              // border: "1px solid #A8C2D7",
              padding: "2%",
              fontSize: "1vmin",
              height: "fit-content",
              cursor: "pointer",
              color: "#A8C2D7",
              borderRadius: "10px",
            }}
          >
            <span style={{
              color: "#394F65",
              fontSize: "2.0vmin",
              fontWeight: "bold",
            }}>
              <i class="bi bi-trash3">Borrar</i>
            </span>
          </div>
          <div
            // onClick={() => navigate(`/Usuario/Inicio`)} className=" fw-bold booking-all"
            style={{
              background: "transparent",
              // border: "1px solid #A8C2D7",
              padding: "2%",
              fontSize: "1vmin",
              height: "fit-content",
              cursor: "pointer",
              color: "#A8C2D7",
              borderRadius: "10px",
            }}
          >
            <span style={{
              color: "#394F65",
              fontSize: "2.0vmin",
              fontWeight: "bold",
            }}>
              <i className="bi bi-check-lg">Filtrar</i>
            </span>
          </div>

        </div>

        <div className="d-flex py-1">
          <div className=" words_health fw-bold">¿Quién deseas que te entrene?</div>
        </div>
        <div>
          <div className="formSteps  row  ">
            {filterEntrenador.map((objetivo, index) => {
              return (
                <div
                  key={index}
                  ref={objetivo[3]}
                  className="objetivo col-6 col-lg-2 "
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    selectCircleImg(
                      objetivo[3],
                      objetivo[4],
                      index,
                      "objetivo col-6 col-lg-2",
                      filterEntrenador,
                      true
                    )
                  }
                >
                  <div
                    ref={objetivo[4]}
                    id="icon"
                    style={{ backgroundImage: `url(${objetivo[1]})` }}
                    className="rounded-circle  text-center formS-filter"
                  ></div>
                  <div
                    id="text"
                    className="m-2  text-center "
                    style={{
                      color: "var(--elevvaTextTitle)",
                    }}
                  >
                    {objetivo[0]}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="d-flex py-1">
          <div className=" words_health fw-bold col-4" style={{ textAlign: "initial" }}>¿Durante tu entrenamiento que tipo de acompañamiento te gusta que tu entrenador haga?</div>
        </div>
        <div>
          <div className="formSteps  row  ">
            {acompañamiento.map((objetivo, index) => {
              return (
                <div
                  key={index}
                  ref={objetivo[3]}
                  className="objetivo col-6 col-lg-2 "
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    selectCircleImg(
                      objetivo[3],
                      objetivo[4],
                      index,
                      "objetivo col-4 col-lg-2",
                      acompañamiento,
                      true
                    )
                  }
                >
                  <div
                    ref={objetivo[4]}
                    id="icon"
                    style={{ backgroundImage: `url(${objetivo[1]})` }}
                    className="rounded-circle  text-center formS-filter"
                  ></div>
                  <div
                    id="text"
                    className="m-2  text-center "
                    style={{
                      color: "var(--elevvaTextTitle)",
                    }}
                  >
                    {objetivo[0]}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="d-flex py-1">
          <div className=" words_health fw-bold col-4" style={{ textAlign: "initial" }}>¿En que rango de edad prefieres a tu entrenador?"</div>
        </div>
        <div>
          <div className="  row m-auto ">
            {" "}
            {rangoEdad.map((objetivo, index, arr) => {
              return (
                <div
                  key={index}
                  ref={objetivo[1]}
                  className="objetivo_text_ve objetivo_text_small rounded-circle col-4 mx-2 position-relative formS-filter"
                  style={{ cursor: "pointer", alignItems: "center" }}
                  onClick={() =>
                    selectCircleText(
                      objetivo[1],
                      index,
                      "col-4 objetivo_text_ve rounded-circle mx-2 formS-filter",
                      arr,
                      true
                    )
                  }
                >
                  <div
                    id="text"
                    className="rounded-circle d-flex align-items-center text-center position-absolute"
                  >
                    {objetivo[0]}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="my-3" onClick={() => navigate(`/Usuario/groupals`)}>
            <ButtonMob
              className="marginButtonReserva"
              text="Filtrar"
            />
          </div>
      </div>
    </div>
  );
});
