import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactPhoneInput from "react-phone-input-2";
import icoNegativo from "../../../assets/newIcons/ICO-NEGATIVO.svg";
import axios from "axios";
import React from "react";
import "react-phone-input-2/lib/style.css";

import {
  basename,
  ButtonMob,
  ModalInfo,
  NuevaAxiosURL,
  SetModalError
} from "../../../utils/utils";
import "./index.css";



export const FormRegister = (props) => {
  //modal register
  const onSuccess = props.onSuccess
  const elevvaT = props.elevvaT;
  const type = props.type;
  
  const navigate = useNavigate();
  const NameCustomer = elevvaT("cover_start_login_customer");
  const [inputs, setInputs] = useState({});
  const [displayError, setDisplayError] = useState("none");
  const [ErroraGregado, seterroraGregado] = useState(null);
  const [ErroraGregadoDocumentId, seterroraGregadoDocumentId] = useState(null);
  const [displayCorrect, setDisplayCorrect] = useState("none");
  const [DisplayCorrectphone, setDisplayCorrectphone] = useState("none");
  const [DisplayDocumentId, setDisplayDocumentId] = useState("none");
  const [actualIconEye1, setActualIcon1] = useState("bi bi-eye-fill");
  const [actualIconEye2, setActualIcon2] = useState("bi bi-eye-fill");
  const [showOrNo1, setShow1] = useState("password");
  const [showOrNo2, setShow2] = useState("password");
  const [Modal, setModal] = useState(null);
  const [membership, setMembership] = useState(null);

console.log("------------inputs", inputs);
useEffect(() => {
  axios
    .get(NuevaAxiosURL + "memberships/?is_corporate=true")
    .then((res) => setMembership(res.data))
    .catch((err) => console.warn(err.response));
}, []);

const Ocultar = () => {
  setTimeout(() => {
    setModal(null);
  }, 5000);
};
const hideModal = () => {
  setModal(null);
};
const AutoLogin = (event) => {
  let servicio = "users/auth/login/";

  const usuario = event.target[1].value.trim();
  const membership = event.target[0].value.trim();
  const password = event.target[3].value.trim();
  var formData = new FormData();

  formData.append("email", usuario);
  formData.append("password", password);
  axios({
    method: "post",
    contentType: "application/json",
    url: `${NuevaAxiosURL}${servicio}`,
    params: {
      t: new Date().getTime(),
    },
    data: formData,
  })
    .then(async (res) => {
      const data = res.data;

      if (!data) {
        setDisplayError("inherit");
        setTimeout(() => {
          setDisplayError("none");
        }, 5000);
      } else {
        localStorage.my_code = data.access;

        localStorage.type = type;

        const instanceNew = axios.create({
          baseURL: NuevaAxiosURL,
          params: {
            t: new Date().getTime(),
          },

          headers: { Authorization: "Bearer " + data.access },
        });

        await instanceNew.get("users/").then((response) => {
          const data = response.data;
          console.log(data);
          localStorage.first_name = data.first_name;
          localStorage.last_name = data.last_name;
          localStorage.email = data.email;
          localStorage.type = type;
          localStorage.id = data.id;
          localStorage.profile_pic = data.customer.profile_pic;
          localStorage.mobile_numberMob = data.customer.document_id;
          if (
            data.membership &&
            data.membership.membership &&
            data.membership.membership.company.mob_link
          ) {
            localStorage.membershipCompanyElevva =
              data.membership.membership.company.mob_link;
          }

          if (type == "Entrenador") {
            if (data.trainer.is_enabled) {
              document.location.href = `${basename}/${type}/inicio`;
            }
          } else {
            if (data.type == 10) {
              onSuccess();
            }
          }
        });
      }
    })
    .catch((err) => {
      setModal(<SetModalError msg={err.response} handleClick={hideModal} />);
    });
};
const handleShowPass = (how) => {
  if (how == 1) {
    if (actualIconEye1 == "bi bi-eye-fill") {
      setActualIcon1("bi bi-eye-slash-fill");
      setShow1("text");
    } else {
      setActualIcon1("bi bi-eye-fill");
      setShow1("password");
    }
  } else {
    if (actualIconEye2 == "bi bi-eye-fill") {
      setActualIcon2("bi bi-eye-slash-fill");
      setShow2("text");
    } else {
      setActualIcon2("bi bi-eye-fill");
      setShow2("password");
    }
  }
};

// eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjc5Nzg3MTE0LCJpYXQiOjE2Nzk3ODM1MTQsImp0aSI6IjNhMGM0ZmFhMWQ0MTRiNWZiM2VhZmQ4NjlhM2I2YTZjIiwidXNlcl9pZCI6OTd9.Gg4vpLkHDq8VpYasdQuqStritZiFJYqy2FsVYkZDJJw

const handleSubmit = (event) => {
  event.preventDefault();

  if (inputs["mobile_number"] && inputs["mobile_number"].length >= 12&&  inputs["document_id"].length >= 7 && inputs["document_id"].length <= 12 ) {
    var formData = new FormData();
    formData.append("email", inputs["email"]); //mail
    formData.append("mobile_number", inputs["mobile_number"]); //phone
    formData.append("password", inputs["password"]); //pass1
    formData.append("password2", inputs["password2"]); //pass2
    formData.append("type", type == "Usuario" ? 10 : 20); //TYPE
    formData.append("first_name", inputs["first_name"]); //Nombre
    formData.append("last_name", inputs["last_name"]); //Apellido
    formData.append("membership", inputs["membership"]); //membership
    formData.append("document_id", inputs["document_id"]); //no cedula
    axios({
      method: "post",
      contentType: "application/json",
      url: `${NuevaAxiosURL}users/auth/signup/`,
      params: {
        t: new Date().getTime(),
      },
      data: formData,
    })
      .then((res) => {
        const data = res.data;
        console.log(data);
        if (!data) {
          setDisplayError("inherit");
          setTimeout(() => {
            setDisplayError("none");
          }, 5000);
        } else {
          localStorage.membership = inputs["membership"];
          AutoLogin(event);

          //mostrar el modal

          //navigate("/" + NameCustomer + "/welcometoElevva");
        }
      })
      .catch((err) => {
        const msg_error = err.response.data;
        if (msg_error) {
          console.log("error", msg_error);
          if (msg_error[0] && msg_error[0].includes("este tipo de membresía")) {
            setModal(
              <ModalInfo
                error="none"
                textButtonAction="Entendido"
                handleCancellcargue={() => setModal(null)}
                handleReservar={() => setModal(null)}
                iconoWidth={"3rem"}
                textSizeUp={"1.2rem"}
                icono={icoNegativo}
                text={` <br/> No haces parte de el convenio  <br/> que seleccionaste <br/> <br/>
                  <span class="text-color-subtitle-membership">Verifica el convenio e intenta nuevamente</span>  <br/> <br/>`}
              />
            );
          }
          setDisplayCorrect("inherit");
          setTimeout(() => {
            setDisplayCorrect("none");
          }, 5000);
          setDisplayError("inherit");
          setTimeout(() => {
            setDisplayError("none");
          }, 5000);
          if (msg_error.password) {
            const text_response = msg_error.password[0];
            let text_show;
            if (text_response.includes("Password fields")) {
              text_show = `Las contraseñas no coinciden </br>`;
            } else {
              text_show = `La contraseña debe tener al menos 8 caracteres y recomendamos incluir letras mayúsculas y minúsculas, números no consecutivos y/o otros caracteres.`;
            }
            setModal(
              <ModalInfo
                error="false"
                handleClick={hideModal}
                textButtonAction="Entendido"
                classcorrecta={"recuerdaTitle_Start"}
                handleCancellcargue={hideModal}
                title="Hay un error</br>en el registro de </br>tu contraseña"
                classesMsg="colorText_Modal my-3"
                text={text_show}
              />
            );
          }
          if (
            (msg_error[0] && msg_error[0].includes("celular")) ||
            msg_error.mobile_number
          ) {
            setDisplayCorrectphone("inherit");
            setTimeout(() => {
              setDisplayCorrectphone("none");
            }, 5000);
          }

          seterroraGregado(msg_error);
          setTimeout(() => {
            if (ErroraGregado) {
              seterroraGregado(null);
            }
          }, 5000);
        }
      });
  } else {
    if( inputs["document_id"].length <7 || inputs["document_id"].length > 12){
      setDisplayDocumentId("inherit");
      setTimeout(() => {
        setDisplayDocumentId("none");
       
      }, 5000);
    }else{

      setDisplayCorrectphone("inherit");
      setTimeout(() => {
        setDisplayCorrectphone("none");
        if (ErroraGregado) {
          seterroraGregado(null);
        }
      }, 5000);
      inputs["mobile_number"] = inputs["mobile_number"].slice(0, 2);
    }
  }
};
var handleChange = (event) => {
  let name = event.target.name;
  let value = event.target.value;
  setInputs((val) => ({ ...val, [name]: value }));
};
const classes = props.classes || "";
return (
  <div className="total-width-login">
    {Modal}
    <span className="d-md-none text-color-subtitle">
      {type == elevvaT("cover_start_login_customer")
        ? elevvaT("customer_login")
        : elevvaT("trainer_login")}
    </span>
    <form
      className={classes}
      onSubmit={handleSubmit}
      style={{
        gap: "1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflow: "auto",
        padding: "0 1%",
      }}
    >
      <h1 className=" m-0" style={{ fontWeight: "300" }}>
        {elevvaT("Create")}{" "}
        <strong style={{ fontWeight: "bold" }}>{elevvaT("account")}</strong>
      </h1>

      <div className="content-select-member">
        <select
          style={
            !inputs.membership || inputs.membership === ""
              ? { color: "var(--elevvaTextSubtitle)" }
              : null
          }
          placeholder={elevvaT("password_text")}
          name={"membership"}
          className="form-control input-style-elevva p-relative"
          onChange={handleChange}
          required
        >
          <option value={""}>Seleccionar</option>
          {membership && membership.length > 0 ? (
            membership.map((memb) => (
              <option key={memb.id} value={memb.id}>
                {memb.name}
              </option>
            ))
          ) : (
            <option>No hay data</option>
          )}
        </select>

        {/* <div className="select-register-location m-auto">
              <i className="bi bi-chevron-down"></i>
            </div> */}
      </div>
      <input
        type="email"
        placeholder={elevvaT("email_text")}
        name={"email"}
        className="form-control input-style-elevva "
        value={inputs.email || ""}
        onChange={handleChange}
        required
      ></input>
      <div
        className="mt-1 errorsCreateAccount"
        style={{
          display: displayError,
        }}
      >
        {ErroraGregado && ErroraGregado.email ? ErroraGregado.email : null}
      </div>

      <div id={elevvaT("phone")} className={"phone-web my-md-0 "}>
        <ReactPhoneInput
          country={"co"}
          className="PEPITO"
          // countryCallingCodeEditable={true}
          name={"mobile_number"}
          placeholder={elevvaT("phone")}
          value={inputs.mobile_number || ""}
          onChange={(e) =>
            setInputs((val) => ({
              ...val,
              ["mobile_number"]: e,
            }))
          }
          required
        />
      </div>

      <div
        className="errorsCreateAccount"
        style={{
          display: DisplayCorrectphone,
        }}
      >
        {ErroraGregado && ErroraGregado.mobile_number
          ? ErroraGregado.mobile_number
          : ErroraGregado && ErroraGregado[0]
          ? ErroraGregado[0]
          : ErroraGregado &&
            ErroraGregado[0] &&
            ErroraGregado[0].includes("celular")
          ? ErroraGregado[0]
          : "El teléfono debe ser válido"}
      </div>

      <div className="d-flex flex-column flex-md-row col-md-12 justify-content-center  gap-3 gap-md-1">
        <div className="col-md-6" id={elevvaT("password_text")}>
          <div
            className="input-inside-create "
            style={{ position: "relative" }}
          >
            <input
              type={showOrNo1}
              placeholder={elevvaT("password_text")}
              name={"password"}
              className="form-control input-style-elevva p-relative"
              value={inputs.password || ""}
              onChange={handleChange}
              required
            ></input>

            <div
              className="eye-color-location"
              onClick={() => handleShowPass(1)}
            >
              <i className={actualIconEye1}></i>
            </div>
          </div>
        </div>

        <div className="col-md-6" id={elevvaT("password_text")}>
          <div
            className="input-inside-create "
            style={{ position: "relative" }}
          >
            <input
              type={showOrNo2}
              value={inputs.password2 || ""}
              onChange={handleChange}
              placeholder={elevvaT("repeat_password")}
              name={"password2"}
              className="form-control input-style-elevva p-relative"
              required
            ></input>
            <div
              className="eye-color-location"
              onClick={() => handleShowPass(2)}
            >
              <i className={actualIconEye2}></i>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column flex-md-row col-md-12 justify-content-center gap-3 gap-md-1">
        <div className="col-md-6" id={elevvaT("name_create")}>
          <input
            placeholder={elevvaT("name_create")}
            name={"first_name"}
            type="text"
            value={inputs.first_name || ""}
            className="form-control input-style-elevva p-relative"
            onChange={handleChange}
            required
          ></input>
        </div>

        <div className="col-md-6" id={elevvaT("last_name")}>
          <input
            placeholder={elevvaT("last_name")}
            name={"last_name"}
            type="text"
            value={inputs.last_name || ""}
            className="form-control input-style-elevva"
            onChange={handleChange}
            required
          ></input>
        </div>
      </div>
      <input
        type="number"
        placeholder={"No. de Cédula"}
        name={"document_id"}
        className="form-control input-style-elevva "
        value={inputs.document_id || ""}
        onChange={handleChange}
        required
      ></input>
       <div
        className="mt-1 errorsCreateAccount"
        style={{
          display: DisplayDocumentId,
        }}
      >
        {"Ingresa entre 7 y 12 dígitos numéricos"}
      </div>
      <TermsAndPolitics key={"terms"} id={"terms"} elevvaT={elevvaT} />
      {/* <ModalCuenta show={show} onHide={onHide} /> */}

      <div className="d-flex justify-content-center">
        <ButtonMob
          colorButton={`var(--elevvabutonLogin_User)`}
          text={elevvaT("Create")}
          className="m-0 buttonLoginStart"
        />
      </div>
    </form>
  </div>
);
};
export const TermsAndPolitics = (props) => {
  const id = props.id;
  const elevvaT = props.elevvaT;
  return (
    <div id={id} className="d-flex justify-content-center ">
      <div className=" m-auto text-align-center ">
        <div id="TyC" className="text-color-subtitle text-center ">
          &nbsp;&nbsp;{elevvaT("confirm_start")}{" "}
          <a onClick={() => window.open("/tyc")} style={{ cursor: "pointer" }}>
            {elevvaT("Terms_conditions")}
          </a>{" "}
          {elevvaT("and")}
          <a
            onClick={() => window.open("/privacidad")}
            style={{ cursor: "pointer" }}
          >
            {" "}
            {elevvaT("politics")}
          </a>
        </div>
      </div>
    </div>
  );
};
