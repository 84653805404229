// import React, { useRef, useState, useEffect } from "react";
// // import { useGLTF, useAnimations } from "@react-three/drei";
// import { useThree, useLoader, useFrame } from "@react-three/fiber";
// import * as THREE from "three";
// export default function Model({ ...props }) {
//   const group = useRef();
//   const [active, setActive] = useState(false);
//   const { nodes, materials, animations } = useGLTF("/ybot_test.glb");
//   // const { nodes, materials, animations } = useGLTF("/unt111iwqetled.glb");
//   const { actions } = useAnimations(animations, group);

//   useEffect(() => {
//     /* highlight-line */
//     actions["Ybot.Idle"].play(); /* highlight-line */
//   });
//   // var do0 = document.getObjectById("11");

//   return (
//     <group ref={group} {...props} dispose={null}>
//       <group name="ybot_test">
//         <group name="ybot">
//           <primitive object={nodes.mixamorigHips} />

//           <skinnedMesh
//             name="Alpha_Joints"
//             geometry={nodes.Alpha_Joints.geometry}
//             material={materials.Alpha_Joints_MAT}
//             skeleton={nodes.Alpha_Joints.skeleton}
//           >
//             <meshStandardMaterial color={"rgb(224,198,177,1)"} />
//           </skinnedMesh>
//           <skinnedMesh
//             name="Alpha_Surface"
//             geometry={nodes.Alpha_Surface.geometry}
//             material={materials.Alpha_Body_MAT}
//             skeleton={nodes.Alpha_Surface.skeleton}
//             onClick={(event) => setActive(!active)}
//           >
//             <meshStandardMaterial
//               color={active ? `rgb(57,79,101,1)` : `white`}
//             />{" "}
//           </skinnedMesh>
//         </group>
//       </group>
//     </group>
//   );
// }
// // useGLTF.preload("/unt111iwqetled.glb");

// useGLTF.preload("/ybot_test.glb");
//     <group {...props} dispose={null}>
//       <mesh
//         geometry={nodes.Alpha_Surface.geometry}
//         material={materials.Alpha_Body_MAT}
//         onClick={(event) => setActive(!active)}
//       >
//         {" "}
//         <meshStandardMaterial
//           color={active ? "white" : `rgb(203,61,130,1)`}
//         />{" "}
//       </mesh>
//       <mesh
//         geometry={nodes.Alpha_Joints.geometry}
//         material={materials.Alpha_Joints_MAT}
//       >
//         {" "}
//       </mesh>
//     </group>
//   );
// }

// useGLTF.preload("/Y Bot.glb");
//   const { nodes, materials, animations } = useGLTF(
//     "/f8e25565-9091-474b-aed9-67625f51855a.glb"
//   );
//   const { actions } = useAnimations(animations, group);
//   console.log(nodes);
//   return (
//     <group {...props} dispose={null}>
//       <primitive object={nodes.Hips} />
//       <skinnedMesh
//         geometry={nodes.Wolf3D_Body.geometry}
//         material={materials.Wolf3D_Body}
//         skeleton={nodes.Wolf3D_Body.skeleton}
//       />
//       <skinnedMesh
//         geometry={nodes.Wolf3D_Hair.geometry}
//         material={materials.Wolf3D_Hair}
//         skeleton={nodes.Wolf3D_Hair.skeleton}
//       />
//       <skinnedMesh
//         geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
//         material={materials.Wolf3D_Outfit_Bottom}
//         skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton}
//       />
//       <skinnedMesh
//         geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
//         material={materials.Wolf3D_Outfit_Footwear}
//         skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton}
//       />
//       <skinnedMesh
//         geometry={nodes.Wolf3D_Outfit_Top.geometry}
//         material={materials.Wolf3D_Outfit_Top}
//         skeleton={nodes.Wolf3D_Outfit_Top.skeleton}
//       />
//       <skinnedMesh
//         name="EyeLeft"
//         geometry={nodes.EyeLeft.geometry}
//         material={materials.Wolf3D_Eye}
//         skeleton={nodes.EyeLeft.skeleton}
//         morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary}
//         morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences}
//       />
//       <skinnedMesh
//         name="EyeRight"
//         geometry={nodes.EyeRight.geometry}
//         material={materials.Wolf3D_Eye}
//         skeleton={nodes.EyeRight.skeleton}
//         morphTargetDictionary={nodes.EyeRight.morphTargetDictionary}
//         morphTargetInfluences={nodes.EyeRight.morphTargetInfluences}
//       />
//       <skinnedMesh
//         name="Wolf3D_Head"
//         geometry={nodes.Wolf3D_Head.geometry}
//         material={materials.Wolf3D_Skin}
//         skeleton={nodes.Wolf3D_Head.skeleton}
//         morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary}
//         morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences}
//       />
//       <skinnedMesh
//         name="Wolf3D_Teeth"
//         geometry={nodes.Wolf3D_Teeth.geometry}
//         material={materials.Wolf3D_Teeth}
//         skeleton={nodes.Wolf3D_Teeth.skeleton}
//         morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary}
//         morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences}
//       />
//     </group>
//   );
// }

// useGLTF.preload("/f8e25565-9091-474b-aed9-67625f51855a.glb");
