import React from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { useState, useEffect, navigate } from "react";
//spinner
import { HashLoader } from "react-spinners";
import { basename, ButtonArrow, ButtonMob, ModalInfo, NuevaAxiosURL } from "../../utils/utils";
// modal bootstrap


const LoginCustomerConvenio = (props) => {
  const elevvaT = props.elevvaT;
    const navigate = useNavigate();
    const type = 'Usuario';
    const [displayError, setDisplayError] = useState("none");
    const [recover, setRecover] = useState(false);
    const [displayLoading, setDisplayLoading] = useState(null);
    const [autenticado, setautenticado] = useState("norefrescado");
    const [modalInfo, setModalInfo] = useState(null);
  
    const handleSubmit = (event) => {
      const usuario = event.target[0].value.trim();
      const password = event.target[1].value.trim();
      const servicio = "users/auth/login/";
     
  
      setDisplayLoading(
        <div className="spinner-container-login">
          <HashLoader color="#8d97fd" />
        </div>
      );
  
      const formData = new FormData();
      formData.append("email", usuario);
      formData.append("password", password);
     
  
      axios({
        method: "post",
        contentType: "application/json",
        url: `${NuevaAxiosURL}${servicio}`,
        params: {
          t: new Date().getTime(),
        },
        data: formData,
      })
        .then((res) => {
          const data = res.data;
          if (data) {
            if (autenticado == "norefrescado") {
              localStorage.my_code = data.access;
              localStorage.refresh = data.refresh;
             
              //localStorage.setItem('my_code', data.access);
              //localStorage.setItem('refresh', data.refresh);
              //localStorage.setItem('type', type);
  
              const instanceLogin = axios.create({
                baseURL: NuevaAxiosURL,
                params: {
                  t: new Date().getTime(),
                },
                headers: { Authorization: "Bearer " + data.access },
              });
  
              instanceLogin.get("users/").then((response) => {
                let data_user = response.data;
                console.log(data_user);
                localStorage.type = type;
              localStorage.first_name = data_user.first_name;
            localStorage.last_name = data_user.last_name;
            localStorage.email = data_user.email;
            localStorage.mobile_numberMob = data_user.customer.document_id;
            localStorage.id = data_user.id;

            if (data_user.customer) {
              localStorage.profile_pic = data_user.customer.profile_pic;
            }
            if (data_user.membership) {
              localStorage.membership = data_user.membership.membership.id;
              localStorage.membershipEndDate = data_user.membership.end_date;
              localStorage.membershipStartDate =
                data_user.membership.start_date;
            }
            if (
              data_user.membership &&
              data_user.membership.membership.company.mob_link
            ) {
              console.log(data_user.membership.membership.company.mob_link);
              localStorage.membershipCompanyElevva =
                data_user.membership.membership.company.mob_link;
            }

            if (data_user.type == 20 && type == "Entrenador") {
              if (data_user.trainer.is_enabled) {
                alert("entrenador ingresa por otra url");
                document.location.href = `${basename}/${type}/inicio`;
              } else {
                setModalInfo(
                  <ModalInfo
                    error="none"
                    handleClick={() =>
                      localStorage.clear() ||
                      (document.location.href = `${basename}/`)
                    }
                    title="Hola entrenador"
                    text={
                      "Estamos verificando la documentación, en poco tiempo serás parte de nuestro equipo"
                    }
                    classesMsg=""
                  />
                );
              }
            } else if (data_user.type == 10 && type == "Usuario") {
              document.location.href = `${basename}/Dashboard`;
            } else {
              localStorage.clear();

              setModalInfo(
                <ModalInfo
                  error="none"
                  handleClick={() => (document.location.href = `${basename}/`)}
                  title="Error de ingreso"
                  text={"Tu tipo de ingreso es incorrecto"}
                  classesMsg=""
                />
              );
            }
              });
            }
          }
        })
        .catch((err) => {
          if (err.response) {
          
          
            localStorage.clear();
            setDisplayError("inherit");
            setTimeout(() => {
              setDisplayError("none");
            }, 2000);
            setDisplayLoading(null);
          }
        });
  
      event.preventDefault();
    };
  
    const FormLogin = () => {
      const [actualIconEye, setActualIcon] = useState("bi bi-eye-fill");
      const [showOrNo, setShow] = useState("password");
      const handleShowPass = () => {
        if (actualIconEye == "bi bi-eye-fill") {
          setActualIcon("bi bi-eye-slash-fill");
          setShow("text");
        } else {
          setActualIcon("bi bi-eye-fill");
          setShow("password");
        }
      };
      return (
        <div className="total-width-login">
        <span className="d-md-none text-color-subtitle text-left">Ingreso usuario</span>
          <form
            className="LoginForm Registro"
            style={{
              gap: "1rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              
              
            }}
            onSubmit={(e) => handleSubmit(e)}
          >
            <h1 className=" m-0" style={{fontWeight:'300'}}>Bienvenido a <h1 style={{fontWeight:'bold'}}>elevva</h1></h1>
            <input
              type="email"
              placeholder={elevvaT("email_text")}
              name="mail"
              id={props.idMail}
              className="form-control input-style-elevva mx-auto"
              required
            ></input>
            <div
              id="passmail_error"
              className="errorRequest"
              style={{
                display: displayError,
                color: "#CB3D82",
                fontWeight: "400",
              }}
            >
              Correo y/o contraseña incorrectas.{" "}
            </div>
  
            <div className="inputs-inside position-relative">
              <input
                type={showOrNo}
                id={props.idPassword}
                placeholder={elevvaT("password_text")}
                name="pass"
                autoComplete="on"
                className="form-control input-style-elevva mx-auto"
                required
              ></input>
              <div className="eye-color-location" onClick={handleShowPass}>
                <i className={actualIconEye}></i>
              </div>
            </div>
            <div
              id="passmail_error"
              className="errorRequest"
              style={{
                display: displayError,
                color: "#CB3D82",
                fontWeight: "400",
              }}
            >
              Correo y/o contraseña incorrectas.{" "}
            </div>
            {displayLoading}
            <div className="d-flex justify-content-center ">
              <ButtonMob
                className="m-0 buttonLoginStart"
                colorButton={`var(--elevvabutonLogin_User)`}
                text={elevvaT("log_in")}
              />
            </div>
            <div
              onClick={() => navigate(`/selectMembership/Usuario`)}
              className="d-flex  justify-content-center"
            >
        <span  className="text-color-elevva" style={{color:"#9DACBD",fontWeight:"500",marginRight:"5px"}}>¿Eres nuevo? {" "}  </span> {" "}    <u style={{cursor:'pointer'}} className="text-color-elevva"> {' Crea tu cuenta con convenio'}</u>
              
            </div>
            <hr/>
            <div
              onClick={() => navigate(`/recuperar`)}
              className="d-flex  justify-content-center"
            >
              <u style={{cursor:'pointer'}} className="text-color-elevva"> {'¿Olvidaste tu contraseña?'}</u>
              
            </div>
          </form>
        </div>
      );
    };
  
    return (
      <>
        <div className="d-md-none mobile-login w-100">
          <div style={{ height: "calc(100vh - 60vh)" }}>
            <div
              className="containerBGClase"
              style={{
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "/assets/img/createUser_web_image.jpg"
                })`,
                backgroundSize: "cover",
              }}
            >
            
              <div className="button-back-start">
                {/* <ButtonArrow
                  colorBack={`var(--elevvaArrowColor)`}
                  handleClick={() => navigate(-1)}
                /> */}
              </div>
            </div>
          </div>
          <div
            className="mobile-welcome-login-row  text-left w-100"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              
              alignItems: "center",
             
            }}
          >
         
            <FormLogin idMail={'mailResponsiveCon'}  idPassword={'passResponsiveCon'} />
          </div>
        </div>
        <div className=" d-none d-md-flex col-12 " style={{ height: "100vh" }}>
          <div
            className="imagen-LoginCustomer-Welcome"
            style={{
              width: "53%",
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "/assets/img/createUser_web_image.jpg"
              })`,
            }}
          ></div>
          <div
            className=""
            style={{
              width: "50%",
              margin: "auto",
              zIndex: "2",
              height: "100%",
              position: "absolute",
              left: "50%",
              justifyContent: "center",
              flexDirection: "column",
              display: "flex",
            }}
          >
            <div className="box-Welcome-login text-left col-12 d-flex" style={{flexDirection:'column'}}>
            <div className="w-100 mx-auto d-flex">
            <div className="total-width-arrow">
            </div><div className=" d-flex justify-content-center text-color-subtitle text-left">Ingreso usuario</div>
            </div>
              <div className="w-100 mx-auto d-flex">
                <div className="total-width-arrow">
                  {/* <ButtonArrow
                    colorBack={`var(--elevvaArrowColor)`}
                    handleClick={() => navigate(-1)}
                  /> */}
                </div>
                <FormLogin idMail={'mailWebCon'}  idPassword={'passWebCon'} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  ;
}

export default LoginCustomerConvenio;