import {
  perlo,
} from "../../utils/utils";

// import { ModalProfe } from "./ModalProfe"
import React, { useRef } from "react";
import "../inicio/inicio.css";
import { useNavigate, Navigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { ButtonMob } from "../../utils/utils";


const IndividualResumen = (props) => {
  const elevvaT = props.elevvaT;
  const pathHere = props.path;
  const navigate = useNavigate();
  function handleClick(event) {
    props.buttonClick(event);
  }
  const theme1 = {
    axis: {
      fontSize: "3px",

      domain: {
        line: {
          stroke: "#ffff",
          strokeWidth: 0.5,
        },
      },
      ticks: {
        line: {
          stroke: "#ffff",
          strokeWidth: 0.5,
        },
        text: {
          fill: "var(--elevvaArrowColor)",
        },
      },
      legend: {
        text: {
          fontSize: "3vw",

          fontWeight: "700",
        },
      },
    },
    grid: {
      line: {
        stroke: "var(--backgroundGraficGrid)",

        strokeWidth: 1,
      },
    },
  };
  const Barra = [
    [
      "Progreso",
      <i className="bi bi-house"></i>,
      useRef(null),
      useRef(null),
      "/Usuario/Inicio",
    ],
    [
      "Mi perfil",
      <i class="bi bi-person-circle"></i>,
      useRef(null),
      useRef(null),
      "/Usuario/Perfil",
    ],
    [
      "Videos de bienestar y entrenamiento",
      <i className="bi bi-play-btn-fill"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Reserva de clases",
      <i className="bi bi-calendar-date"></i>,
      useRef(null),
      useRef(null),
      "/Usuario/groupals",
    ],
    [
      "Adquiere equipos",
      <i className="bi bi-box2"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Preguntas frecuentes",
      <i className="bi bi-chat-right-quote"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Términos y condiciones políticas de privacidad",
      <i class="bi bi-pencil-square"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Cerrar sesión",
      <i className="bi bi-box-arrow-left"></i>,
      useRef(null),
      useRef(null),
      "out",
    ],
  ];
  const [counter, setcounter] = useState(0);
  const [modalInfo, setModalInfo] = useState(null);
  useEffect(() => {
    const rootr = document.documentElement;
    var number = document.getElementById("number");
    var count = 0;
    rootr.style.setProperty(
      "--percentageCircularBar",
      300 - (300 * counter) / 100 + "%"
    );
    setInterval(() => {
      if (count === counter) {
        clearInterval();
      } else {
        count += 1;
        if (number) number.innerHTML = count + "%";
      }
    }, 30);
  }, [counter]);
  useEffect(() => {
    setcounter(50);
  }, []);


  const selectOption = (
    refContainer,
    index,
    classess,
    arr,
    onlyone,
    pathHere
  ) => {
    if (onlyone === true) {
      for (const iterator of arr) {
        
        iterator[2].current.classList = classess;
        iterator[2].current.active = false;
        iterator[3] = false;
      }
    }

    if (refContainer.current.active === true) {
      refContainer.current.classList = classess;
      refContainer.current.active = false;
    } else {
      refContainer.current.classList = classess + " active";
      refContainer.current.active = true;
    }
    if (pathHere === "out") {
      perlo();
    } else {
      return navigate(pathHere);
    }
  };

  const categorias = [
    [
      "Clases",
      "grupales",
      process.env.PUBLIC_URL + "/assets/img/category_grupal.jpg",
    ],
    [
      "Intensidad",
      "baja",
      process.env.PUBLIC_URL + "/assets/img/category_baja.jpg",
    ],
    [
      "Intensidad",
      "media",
      process.env.PUBLIC_URL + "/assets/img/category_media.jpg",
    ],
    [
      "Intensidad",
      "alta",
      process.env.PUBLIC_URL + "/assets/img/category_alta.jpg",
    ],
  ];

  const entrenamientoHoras = [
    {
      dia: "Enero 13",
      credito: "2 créditos",
      hora: "9:00 / 10:00 am"
    },
    {
      dia: "Enero 19",
      credito: "2 créditos",
      hora: "9:00 / 10:00 am"
    },
    {
      dia: "Enero 13",
      credito: "2 créditos",
      hora: "9:00 / 10:00 am"
    }
  ]

  return (
    <div
      className="d-flex col-12 "
      style={{
        height: "100%",
      }}
    >
      <div
        className="col-2"
        style={{
          flexDirection: "column",
        }}
      >
        <div
          className=" w-100 mx-auto py-4  text-center"
          style={{ height: "20%" }}
        >
          <img
            alt="logo_alta_black"
            style={{ padding: "5% 20%", width: "80%" }}
            src={process.env.PUBLIC_URL + "/assets/img/logo_alta_black.png"}
          />
        </div>
        <div
          className=" w-100"
          style={{
            height: "80%",
          }}
        >
          {Barra.map((elementos, index) => (
            <div
              key={index}
              ref={elementos[2]}
              style={{ fontWeight: "600" }}
              className="d-flex option-page text-color-title  py-3 "
              onClick={() =>
                selectOption(
                  elementos[2],
                  index,
                  "d-flex option-page fw-bold  py-3 ",
                  Barra,
                  true,
                  elementos[4]
                )
              }
            >
              <div className="my-auto mx-2 Iconresponsive">{elementos[1]} </div>
              <div className="mx-2 letraPequeña">{elementos[0]} </div>
            </div>
          ))}
        </div>
      </div>
      {modalInfo}
      <div
        className="col-10  position-relative"
        style={{
          background: "rgba(0,0,0,.7)",
          height: "100vh",

          padding: "2% 3% 2% 2%",
        }}
      >
        <div className="d-flex col-12" style={{ gap: "2%", height: "100%" }}>
          <div
            className="col-3"
            style={{
              background: "white",
              height: "100%",
              padding: "2%",
              borderRadius: "20px",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="d-flex my-auto">
              <div
                onClick={() => navigate(`/Usuario/Inicio`)}
                className=" fw-bold booking-all"
                style={{
                  background: "transparent",
                  border: "1px solid #A8C2D7",
                  padding: "2%",
                  fontSize: "1vmin",
                  height: "fit-content",
                  cursor: "pointer",
                  color: "#A8C2D7",
                  borderRadius: "10px",
                }}
              >
                <span style={{ fontSize: "0.5vmin" }}>
                  <i className="bi bi-chevron-left"></i>
                </span>
              </div>
              <span
                className="fw-bold my-auto ms-2"
                style={{
                  color: "var(--elevvaTextTitle)",
                  fontSize: "2.0vmin",
                }}
              >
                {" "}
                Mis reservas Elevva
              </span>
            </div>
            <hr />
            <div style={{ lineHeight: "110%", margin: "2vmin auto" }}>
              <span
                style={{
                  color: "rgba(57,79,101,0.5)",
                  fontSize: "2.0vmin",
                }}
              >
                Selecciona
              </span>
              <br />
              <span
                className="fw-bold"
                style={{
                  color: "var(--elevvaTextTitle)",
                  fontSize: "2.0vmin",
                }}
              >
                {" "}
                Categoria de interés
              </span>
            </div>
            {categorias.map((e) => (
              <div
                className="position-relative mx-auto"
                style={{
                  backgroundImage: `url(${e[2]})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "20px",
                  width: "100%",
                  height: "18%",
                  marginTop: "1.5vmin",
                  marginBottom: "1.5vmin",
                }}
              >
                <div
                  className="position-absolute "
                  style={{
                    color: "var(--elevvaTextTitle)",
                    display: "flex",
                    height: "100%",
                    left: "5%",
                    justifyContent: "center",
                    flexDirection: "column",
                    lineHeight: "90%",
                  }}
                >
                  <span className="letraPequeñaother mb-1">{e[0]}</span>
                  <div style={{ maxWidth: "10vmin", lineHeight: "110%" }}>
                    <span className="letraPequeñaCardTitleBook fw-bold">
                      {e[1]}
                    </span>
                  </div>
                </div>

                <div
                  className="position-absolute fw-bold booking-all"
                  style={{
                    background: "var(--backgroundOnboardingObjective)",
                    bottom: "9%",
                    right: "5%",
                    padding: "2%",
                    fontSize: "1vmin",
                    color: "var(--white)",
                    borderRadius: "10px",
                  }}
                >
                  <span style={{ fontSize: "0.5vmin" }}>
                    <i className="bi bi-chevron-right"></i>
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div
            className="col-9 d-flex"
            style={{
              background: "white",
              height: "100%",
              padding: "2%",
              justifyContent: "center",
              borderRadius: "20px",
            }}
          >
            <div
              className="col-6 d-flex"
              style={{ position: "relative", justifyContent: "center" }}
            >
              <img
                src={
                  process.env.PUBLIC_URL + "/img/mascarareservafinal.png"} />

            </div>
            <div
          className="col-6 d-flex"
          style={{
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            padding: "2%",
          }}
        >
          <div className="d-flex my-auto">
          
            <div
              // onClick={() => setoptions("seleccionar")}
              className=" fw-bold booking-all"
              style={{
                background: "transparent",
                border: "1px solid #A8C2D7",
                padding: "2%",
                fontSize: "1vmin",
                height: "fit-content",
                cursor: "pointer",
                color: "#A8C2D7",
                borderRadius: "10px",
              }}
            >
              <span style={{ fontSize: "0.5vmin" }}>
                <i className="bi bi-chevron-left"></i>
              </span>
            </div>
            <div className=" my-auto mx-2">
              <div
                className="my-0"
                style={{ color: "#394F65", fontSize: "2.5vmin" }}
              >
                Resumen
              </div>
              <div
                style={{
                  color: "#394F65",
                  fontWeight: "bold",
                  fontSize: "3vmin",
                  whiteSpace: "nowrap",
                }}
              >
                de tu reserva
              </div>{" "}
              <div
                className="d-flex"
                style={{
                  background: "#F4F0ED",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderRadius: "25px",
                  padding: "5%",
                  width: "38vmin",
                  margin: "8vmin 0",
                }}
              >
                <span style={{ textAlign: "center", fontSize: "3vmin" }}>
                  <i className="bi bi-clock-history"></i>
                </span>

                <span
                  style={{
                    textAlign: "center",
                    color: "#394F65",
                    fontWeight: "bold",
                    fontSize: "1.5vmin",
                    whiteSpace: "nowrap",
                  }}
                >
                  Prof. Hugo Ortiz
                </span>
                <span
                  style={{
                    textAlign: "center",
                    color: "#D74688",
                    fontWeight: "bold",
                    fontSize: "1.5vmin",
                    whiteSpace: "nowrap",
                  }}
                >
                  Zumba grupal
                </span>
                <span
                  style={{
                    textAlign: "center",
                    color: "#394F65",
                    fontWeight: "bold",
                    fontSize: "1.5vmin",
                    whiteSpace: "nowrap",
                  }}
                >
                  Agosto 17 de 2020 / 9:00 a.m / 10:00 a.m
                </span>
              </div>
              <hr />
              <div className="d-flex py-2" style={{ justifyContent: "center" }}>
                <button
                onClick={() => navigate("/Usuario/Inicio")}
                  // onClick={() => setoptions("reservafinal")}
                  style={{
                    background: "var(--elevva)",
                    border: "1px solid var(--elevva)",
                    borderRadius: "20px",
                    color: "var(--white)",
                    fontWeight: "400",
                    padding: "2% 12%",
                  }}
                >
                  Reservar
                </button>
              </div>
            </div>
          </div>
        </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualResumen;
